//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getUserCommissionData } from '@/api/personalAnalysis';
import MyExportExcel from '@/components/MyExportExcel/common';
export default {
  name: 'saleCommisionData',
  data: function data() {
    return {
      // 系统教程
      course: this.$route.query.course,
      titles: '销售提成数据',
      orderTimeValue: '2022',
      orderTimeValue1: '2022/4',
      dateOptions: [],
      dateOptionsValue: [],
      nameInput: '',
      tableData: [],
      tableLoading: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // 默认下拉时间
    this.dateOptions = [];
    for (var _i = 1; _i < 6; _i++) {
      var obj = {
        id: _i,
        value: 2021 + _i + '',
        label: 2021 + _i + ''
      };
      this.dateOptions.push(obj);
    }
    this.dateOptionsValue = [];
    // 获取当前年月 默认为当前年月
    this.orderTimeValue = new Date().getFullYear() + '';
    this.orderTimeValue1 = "".concat(this.orderTimeValue, "/").concat(new Date().getMonth() + 1);
    var val = this.orderTimeValue;
    var i = 1;
    // 最早从2022年4月开始
    if (this.orderTimeValue == '2022') {
      i = 4;
    }
    for (i; i < 13; i++) {
      var _obj = {
        id: i,
        value: "".concat(val, "/").concat(i),
        label: "".concat(val, "-").concat(i)
      };
      this.dateOptionsValue.push(_obj);
    }
    // 列表数据加载
    this.getListFun();
  },
  components: {
    Head: Head,
    MyExportExcel: MyExportExcel
  },
  methods: {
    getListFun: function getListFun() {
      var _this = this;
      this.tableLoading = true;
      var days = this.getMonthDays("".concat(this.orderTimeValue1, "/01"));
      var params = {
        start_time: parseInt(new Date("".concat(this.orderTimeValue1, "/01 00:00:00")).getTime() / 1000),
        end_time: parseInt(new Date("".concat(this.orderTimeValue1, "/").concat(days, " 23:59:59")).getTime() / 1000),
        user_name: this.nameInput,
        is_export: 0
      };
      getUserCommissionData(params).then(function (res) {
        _this.tableData = res.data;
        _this.ownFun(_this.tableData, 1);
      }).finally(function () {
        _this.tableLoading = false;
      });
    },
    // 递归
    ownFun: function ownFun(data, other) {
      var j = other + 1;
      for (var i = 0; i < data.length; i++) {
        // 追加一个唯一的值
        this.$set(data[i], 'uuid', Math.random(10) + i);
        if (data[i].children) {
          this.ownFun(data[i].children, j);
        }
      }
    },
    // 时间
    changeOptionsFun: function changeOptionsFun(val, index) {
      if (index != 1) {
        this.dateOptionsValue = [];
        var i = 1;
        if (this.orderTimeValue == '2022') {
          i = 4;
        }
        for (i; i < 13; i++) {
          var obj = {
            id: i,
            value: "".concat(val, "/").concat(i),
            label: "".concat(val, "-").concat(i)
          };
          this.dateOptionsValue.push(obj);
        }
      }
      if (index == 0) {
        this.orderTimeValue1 = this.dateOptionsValue[0].value;
      }
    },
    downLoadExcelFun: function downLoadExcelFun() {
      var url = 'UserCommissionData/getUserCommissionData';
      var days = this.getMonthDays("".concat(this.orderTimeValue1, "/01"));
      var params = {
        start_time: parseInt(new Date("".concat(this.orderTimeValue1, "/01 00:00:00")).getTime() / 1000),
        end_time: parseInt(new Date("".concat(this.orderTimeValue1, "/").concat(days, " 23:59:59")).getTime() / 1000),
        user_name: this.nameInput,
        is_export: 1
      };
      var currentTime = this.currentTime();
      var title = "".concat(currentTime, "_\u9500\u552E\u63D0\u6210\u6570\u636E.xlsx");
      this.$refs.downloadExcelRef.propDataFun(url, params, title);
    },
    // 获取那个月有几天
    getMonthDays: function getMonthDays(date1) {
      var date = new Date(date1);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var days = new Date(year, month, 0);
      return days.getDate();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container Staffing" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                    [_c("Head", { attrs: { name: _vm.titles } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {},
                          [
                            _vm._v(
                              "\n                        日期范围：\n                        "
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                on: {
                                  change: function (val) {
                                    return _vm.changeOptionsFun(val, 0)
                                  },
                                },
                                model: {
                                  value: _vm.orderTimeValue,
                                  callback: function ($$v) {
                                    _vm.orderTimeValue = $$v
                                  },
                                  expression: "orderTimeValue",
                                },
                              },
                              _vm._l(_vm.dateOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                on: {
                                  change: function (val) {
                                    return _vm.changeOptionsFun(val, 1)
                                  },
                                },
                                model: {
                                  value: _vm.orderTimeValue1,
                                  callback: function ($$v) {
                                    _vm.orderTimeValue1 = $$v
                                  },
                                  expression: "orderTimeValue1",
                                },
                              },
                              _vm._l(_vm.dateOptionsValue, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "name-input-class",
                              attrs: {
                                size: "small",
                                placeholder: "顾问名称搜索",
                              },
                              model: {
                                value: _vm.nameInput,
                                callback: function ($$v) {
                                  _vm.nameInput = $$v
                                },
                                expression: "nameInput",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "new-input-class",
                                attrs: { size: "small", type: "success" },
                                on: { click: _vm.getListFun },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "warning" },
                                on: { click: _vm.downLoadExcelFun },
                              },
                              [_vm._v("导出")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        data: _vm.tableData,
                        "row-key": "uuid",
                        border: "",
                        "default-expand-all": "",
                        height: "650",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                        "tree-props": {
                          children: "children",
                          hasChildren: "hasChildren",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "name",
                          label: "部门名称",
                          width: "300",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.user_name
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.user_name) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(scope.row.name) + " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2444868987
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          align: "center",
                          prop: "professional_level",
                          label: "职级",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          align: "center",
                          prop: "basic_salary",
                          label: "职级底薪(元)",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual_sales",
                          label: "个人业绩指标(万元)",
                          width: "200",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual_kpi_ratio",
                          label: "个人业绩提点(%)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.individual_kpi_ratio
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.individual_kpi_ratio
                                            ) +
                                            "% "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1240542972
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "is_leader",
                          label: "是否为主管",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.is_leader == 1 ||
                                  scope.row.is_leader == 0
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [_vm._v(" 是 ")])
                                          : _c("span", [_vm._v(" 否 ")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          873327240
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_sales",
                          label: "团队业绩指标(万元)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_sales
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.group_sales
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4229105760
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_kpi_ratio",
                          label: "团队业绩提点(%)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_kpi_ratio
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.group_kpi_ratio
                                                  ) +
                                                  "% "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          943816069
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "total_collection_amount",
                          label: "个人收款总额(万元)",
                          width: "200",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual_sales_completion_rate",
                          label: "个人业绩完成率(%)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.individual_sales_completion_rate
                                    ? _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row
                                                  .individual_sales_completion_rate
                                              ) +
                                              "% "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3610700816
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "commission_tuition_fees",
                          label: "个人参与提成的学费总额(万元)",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual_commission",
                          label: "个人提成(元)",
                          width: "200",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_total_collection_amount",
                          label: "团队收款总额(万元)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_total_collection_amount
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row
                                                      .group_total_collection_amount
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1474659040
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_sales_completion_rate",
                          label: "团队业绩完成率(%)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_sales_completion_rate
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row
                                                      .group_sales_completion_rate
                                                  ) +
                                                  "% "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3140905477
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_total_tuition_fees",
                          label: "团队参与提成的学费总额(万元)",
                          width: "180",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_total_tuition_fees
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row
                                                      .group_total_tuition_fees
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3104055456
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "group_commission",
                          label: "团队提成(元)",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.group_commission
                                    ? _c("div", [
                                        scope.row.is_leader == 1
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.group_commission
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4104653856
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "total_commission",
                          label: "总提成(元)",
                          width: "200",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("my-export-excel", {
        ref: "downloadExcelRef",
        staticStyle: { display: "none" },
        attrs: { dialogType: 0 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }